import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./founder-details.module.scss"
import { Markdown } from "@components/markdown/markdown"
import { Link } from "@components/link/link"

export type FounderResource = {
  image: string
  overline: string
  title: string
  urlLink: string
}

type FounderDetailsProps = {
  content: {
    image: string
    overline: string
    title: string
    description: string
  }
  overline: string
  resources: Array<FounderResource>
  className?: string
}

export const FounderDetails: FunctionComponent<FounderDetailsProps> = ({
  content,
  overline,
  resources,
  className
}) => (
  <div className={classNames(styles.founder, styles.green, className)}>
    <div className={styles.content}>
      <div className={styles.image}>
        <Link to="https://learn.ligonier.org/teachers/rc-sproul">
          <img data-src={content?.image} alt="" className="lazyload" />
        </Link>
      </div>
      <div className={styles.text}>
        <span className={styles.overline}>{content.overline}</span>
        <Link to="https://learn.ligonier.org/teachers/rc-sproul">
          <Markdown className={styles.name} content={content.title} />
        </Link>
        <Markdown className={styles.info} content={content.description} />
      </div>
    </div>
    {resources && (
      <div className={styles.resources}>
        <div className={styles.header}>
          <span>{overline}</span>
        </div>
        <div className={styles.container}>
          {resources.map((resource, index) => (
            <Link to={resource.urlLink} className={styles.resource} key={index}>
              <img data-src={resource.image} alt="" className="lazyload" />
              <span className={styles.type}>{resource.overline}</span>
              <span className={styles.text}>{resource.title}</span>
            </Link>
          ))}
        </div>
      </div>
    )}
  </div>
)
